<div class="content space top">

<mat-card class="card">
    <mat-card-header>
      <mat-card-title >Technischer Support</mat-card-title>
      <mat-card-subtitle class="links" >Clients</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="cardContent">
        <div class="links" >
            <p>Tel.: 03448 / 755-444</p>
            
            <p class="line">Mo - Do: 08:00 Uhr - 17:00 Uhr</p>
            <p class="line">Fr:      08:00 Uhr - 16:00 Uhr</p>
            <p></p>
        </div>
    </mat-card-content>
    <mat-card-actions class="buttonBottom" >
        <a mat-button color="accent" href="mailto:support@bluechip.de">support&#64;bluechip.de</a>
    </mat-card-actions>
  </mat-card>

<mat-card class="card">
    <mat-card-header>
      <mat-card-title >Technischer Support</mat-card-title>
      <mat-card-subtitle class="links" >Server</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="cardContent">
        <div class="links" >
            <p>Tel.: 03448 / 755-330</p>
            <div style="line-height: 1;">
              <div>
                <p class="line">Mo - Do: 08:00 Uhr - 17:00 Uhr</p>
                <p class="line">Fr: 08:00 Uhr - 16:00 Uhr</p>
            </div>
            </div>
            
            <p></p>
        </div>
    </mat-card-content>
    <mat-card-actions class="buttonBottom" >
        <a class="paddingLeft" mat-button color="accent" href="mailto:serversupport@bluechip.de">serversupport&#64;bluechip.de</a>
    </mat-card-actions>
  </mat-card>

  <mat-card class="card">
    <mat-card-header>
      <mat-card-title >Technischer Support</mat-card-title>
      <mat-card-subtitle class="links">Cloud Services</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="cardContent">
        <div class="links">
            <p></p>
            <p><br></p>
            <p class="line">Mo - Do: 08:00 Uhr - 17:00 Uhr</p>
            <p class="line">Fr:      08:00 Uhr - 16:00 Uhr</p>
            <p></p>
        </div>
    </mat-card-content>
    <mat-card-actions class="buttonBottom" >
        <a  mat-button color="accent" href="mailto:support@bluechip-cloud.de">support&#64;bluechip-cloud.de</a>
    </mat-card-actions>
  </mat-card>

  <mat-card class="card">
    <mat-card-header>
      <mat-card-title >Service-Center</mat-card-title>
      <mat-card-subtitle ><br></mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="cardContent">
        <div class="links" >
            <p>Tel.: 03448 / 755-320</p>
                       
            <p class="line">Mo - Do: 08:00 Uhr - 17:00 Uhr</p>
            <p class="line">Fr:      08:00 Uhr - 16:00 Uhr</p>
            <p></p>
        </div>
    </mat-card-content>
    <mat-card-actions class="buttonBottom" >
        <a mat-button color="accent" href="mailto:service@bluechip.de">service&#64;bluechip.de</a>
    </mat-card-actions>
  </mat-card>
</div>